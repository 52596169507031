import React, { Component } from "react";
import Header from "./header";
import MenuBar from "./navbar";
import SocialMediaLinks from "./socialmedialinks";
import { BASE_URL } from "./utility";
import Card from "react-bootstrap/Card";
import CardDeck from "react-bootstrap/CardDeck";
import { Button } from "react-bootstrap";
class Brochures extends Component {
  constructor() {
    super()
    this.state = {
      cardData: [],
    };
  }
  state = {};

  componentDidMount() {
    this.fetchData();
  }
  fetchData = async () => {
    const response = await fetch(BASE_URL + 'filenames/pdfs');
    const response1 = await response.json();
    let carDat = [];
    for (let i = 0; i < response1.length; i++) {
      carDat.push({
        imgSrc: BASE_URL + "download/pdfthumbs/" + response1[i] ,
        title: response1[i].toUpperCase(),
        text: response1[i].toUpperCase(),
        pdfLink: BASE_URL + "download/pdfs/" + response1[i] + ".pdf"
      })
    }
    this.setState({ cardData: carDat });
  }

  chunkArray(arr, chunkSize) {
    const chunkedArray = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
      chunkedArray.push(arr.slice(i, i + chunkSize));
    }
    return chunkedArray;
  }
  render() {
    const chunkedCardData = this.chunkArray(this.state.cardData, 4);

    return (
      <div style={{ backgroundImage: `url(${BASE_URL + "download/general/bg"})` }}>
        <Header />
        <MenuBar />
        <center>
          <h2 style={{ color: "white" }}>Brochures</h2>
          <div>
            {chunkedCardData.map((chunk, chunkIndex) => (
              <CardDeck key={chunkIndex}>
                {chunk.map((card, index) => (
                  <Card key={index} className="mb-4">
                     <Card.Img variant="top" src={card.imgSrc} width={100} height={120} />
                    <Card.Body>
                      <Card.Title>{card.title}</Card.Title>
                      <Card.Text>{card.text}</Card.Text>
                      <Button variant="primary" href={card.pdfLink}>
                        Explore
                      </Button>
                    </Card.Body>
                  </Card>
                ))}
              </CardDeck>
            ))}
          </div>
        </center>
        <div>&nbsp;</div>
        <div>&nbsp;</div>

        <SocialMediaLinks />
        <div>&nbsp;</div>
      </div>
    );
  }
}

export default Brochures;
