import React, { useState } from 'react';
import {BASE_URL,PRODUCT_UPLOADS} from "./utility";
import MenuBar from "./navbar";
import SocialMediaLinks from "./socialmedialinks";
import Header from './header';
import Button from 'react-bootstrap/Button';
import { Redirect } from 'react-router-dom';
const ImageDeletion = () => {
  const [selectedImages, setSelectedImages] = useState([]);
  const [imageList, setImageList] = useState([]);
  const handleImageClick = (id) => {
    const isSelected = selectedImages.includes(id);
    if (isSelected) {
      setSelectedImages(selectedImages.filter((imageId) => imageId !== id));
    } else {
      setSelectedImages([...selectedImages, id]);
    }
    };
  const handleCategoryChange = async (event) =>
    {
    const category = event.target.value;
    setImageList([])
    const response = await fetch(BASE_URL+'filenames/'+category);
    const imageNames = await response.json();
    const updatedImageList = getImageList(category,imageNames);
    setImageList(updatedImageList);
    }
  const onFileDelete = (e) => {
    let imgstr = ""
    let category = ""
    for(let imgindex in selectedImages)
    {
      if(selectedImages[imgindex].includes('http://rainbowtouchindia.com/rtiservices/download/'))
      {
        let temp=selectedImages[imgindex].replace('http://rainbowtouchindia.com/rtiservices/download/','')
        category=temp.split("/")[0]
        imgstr+=temp.split("/")[1]+'.jpg'+","
      }
    }
    imgstr = imgstr.replace(/,\s*$/, '');
    e.preventDefault();
    if (window.confirm('Are you sure you want delete selected image !!')) {
      fetch(BASE_URL + 'deleteImage/'+category+'/'+imgstr, {
                method: 'delete'
        }).then(res => res.text())
          .then(text => {
                  window.location.href = '/imageDeletion';  
                });
              }
    };
  const imageGalleryStyle = {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
      };
  const imageStyle = {
        margin: '5px',
        width: '200px',
        height: '200px',
        objectFit: 'cover',
      };
  const selectedImageStyle = {
        ...imageStyle,
        border: '5px solid blue'
      };
  const getImageList =  (category,imageNames) => {
        let images =[]
        let counter = 0
        for (let i in imageNames)
        {
            counter=counter+1;
            images.push({ id: counter, src: 'http://rainbowtouchindia.com/rtiservices/download/'+category+'/'+imageNames[i] })
        }
        return images;
      };
      if (!localStorage.getItem("token"))
      return <Redirect to='/auth' />
      else
  return (
   
            <div style={{ backgroundImage:`url(${BASE_URL+"download/general/bg"})` }}>
             <Header />
    
        <center>
          <h2 style={{ color: "white"}}>Delete Images </h2>
          <MenuBar />
        
        <br></br>
        <select style={{ color: "white", backgroundColor: "grey",borderRadius:"20px" ,width:"40%"}} className="form-control" name="productType" id="productType" required onChange={handleCategoryChange}>
                {PRODUCT_UPLOADS.map(item => {
                  return (<option key={item.value} value={item.value}>{item.text}</option>);
                })}
              </select>
              </center>
    <div  >   
      <div style={imageGalleryStyle}>
        {imageList.map((image) => (
          <div
            key={image.id}
            className={`image ${selectedImages.includes(image.src) ? 'selected' : ''}`}
            onClick={() => handleImageClick(image.src)}
          >
          <img style={selectedImages.includes(image.src) ? selectedImageStyle : imageStyle} src={image.src} alt={image.id} width="250px" height="150px"/>
          </div>
        ))}
      </div>
      <center>
      <Button className="btn-danger  border border-white rounded-pill font-weight-bold" onClick={onFileDelete} style={{ backgroundColor:"red"}}>
				Delete !!
				</Button>
        </center>
    </div>
    
    <div>&nbsp;</div>
        <SocialMediaLinks />
        <div>&nbsp;</div>
    </div>
  );
};

export default ImageDeletion;
