import React, { Component } from "react";
import MenuBar from "./navbar";
import SocialMediaLinks from "./socialmedialinks";
import Header from './header';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import  { Redirect } from 'react-router-dom';
import {BASE_URL} from "./utility";

class Operations extends Component {
  state = {};

  handleUploadFile() {
    window.location.href="/uploadimage";
  }

  generateTaxInvoice()
  {
    window.location.href="taxinvoice";
  }
  generatePermormaInvoice()
  {
    window.location.href="performainvoice";
  }
  generateCreditNote()
  {
    window.location.href="creditnote";
  }
  generateInvoiceReports()
  {
    window.location.href="invoicereports";
  }

  handleDeleteFile()
  {
    window.location.href="imageDeletion";
  }


  render() {
    const myStyle = {
      color: "white",
      padding: "5%",
      fontFamily: "Sans-Serif",
      width: '100%',
      margin: '0 auto',
    };
      if (!localStorage.getItem("token"))
        return <Redirect to='/auth'  />
      else
      return (
        <div style={{ backgroundImage:`url(${BASE_URL+"download/general/bg"})` }}>
        <Header />
        <center>
          <MenuBar />
          <h2 style={{ color: "white" }}>Operations </h2>
       
        <br></br>
        <ButtonGroup  className="btn-group-sm"  style={myStyle}  vertical>
          <Button className="btn-secondary border  rounded-pill font-weight-bold" onClick={this.generateTaxInvoice}>Generate Tax Invoice</Button>
          <br></br>
          <Button className="btn-primary border  rounded-pill font-weight-bold" onClick={this.generatePermormaInvoice}>Generate Pro-forma Invoice</Button>
          <br></br>
          <Button className="btn-light border  rounded-pill font-weight-bold" onClick={this.generateCreditNote}>Generate Credit Note</Button>
          <br></br>
          <Button className="btn-success  border  rounded-pill font-weight-bold" onClick={this.generateInvoiceReports}>View Reports</Button>
          <br></br>
          <Button className="btn-info  border  rounded-pill font-weight-bold" onClick={this.handleUploadFile}>Upload Images</Button>
          <br></br>
          <Button className="btn-danger  border  rounded-pill font-weight-bold" onClick={this.handleDeleteFile}>Delete Images</Button>
          <br></br>
        </ButtonGroup>
        </center>
        <div>&nbsp;</div>
        <SocialMediaLinks />
        <div>&nbsp;</div>
      </div>
      );
  }
  }


export default Operations;
