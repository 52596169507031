import React from "react";
import {BASE_URL} from "./utility";
export default function SocialMediaLinks() {
  return (
    <div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <a href="https://www.facebook.com/rainbowtouchindia/">
          <img
            height={40}
            width={40}
            className="d-block"
            src={BASE_URL+"download/social/facebook"}
            alt="Facebook"
          />
        </a>
        <div>&nbsp;</div>
        <div>&nbsp;</div>
        <a href="https://mail.google.com/mail/u/0/?view=cm&fs=1&to=rainbowtouchindia@gmail.com&su=Enquiry&tf=1">
          <img
            height={40}
            width={40}
            className="d-block"
            src={BASE_URL+"download/social/gmail"}
            alt="Gmail"
          />
        </a>
        <div>&nbsp;</div>
        <div>&nbsp;</div>
        <a href="https://www.instagram.com/">
          <img
            height={40}
            width={40}
            className="d-block"
            src={BASE_URL+"download/social/insta"}
            alt="Insta"
          />
        </a>
        <div>&nbsp;</div>
        <div>&nbsp;</div>
        <a href="https://twitter.com/">
          <img
            height={40}
            width={40}
            className="d-block"
            src={BASE_URL+"download/social/twitter"}
            alt="Twitter"
          />
        </a>
        <div>&nbsp;</div>
        <div>&nbsp;</div>
      </div>
      <div>
        <footer>
          <div className="text-center p-3">
            © 2005 Copyright:
            <a className="text-dark" href="http://www.rainbowtouchindia.com/">
              rainbowtouchindia
            </a>
          </div>
        </footer>
      </div>
    </div>
  );
}
