import React, { Component  } from "react";
import Header from "./header";
import MenuBar from "./navbar";
import SocialMediaLinks from "./socialmedialinks";
import Gallery from "react-photo-gallery";
import {BASE_URL,getImages} from "./utility";
class NewProducts extends Component {
  
  constructor() {
    super()
    this.state = {
      newarrival: '',
      dpanels: '',
      fabio:'',
      floorings:'',
      indoorwpclouvers:'',
      outdoorwpclouvers:'',
      fundermax:'',
      hpl:'',
      wpcbaffleceilings:'',
      soffitpanels:''
    };
  }
  state = {};
  componentDidMount() {
    this.fetchData();
   }

   fetchData = async () => {
    const response1 = await fetch(BASE_URL+'filenames/newarrivals')
    const respVal1= await response1.json()
    this.setState({newarrival:respVal1})
    const response2 = await fetch(BASE_URL+'filenames/3d_panels')
    const respVal2= await response2.json()
    this.setState({dpanels:respVal2})
    const response3 = await fetch(BASE_URL+'filenames/fabio')
    const respVal3= await response3.json()
    this.setState({fabio:respVal3})
    const response4 = await fetch(BASE_URL+'filenames/floorings')
    const respVal4= await response4.json()
    this.setState({floorings:respVal4})
    const response5 = await fetch(BASE_URL+'filenames/indoorwpclouvers')
    const respVal5= await response5.json()
    this.setState({indoorwpclouvers:respVal5})
    const response6 = await fetch(BASE_URL+'filenames/outdoorwpclouvers')
    const respVal6= await response6.json()
    this.setState({outdoorwpclouvers:respVal6})
    const response7 = await fetch(BASE_URL+'filenames/hpl')
    const respVal7= await response7.json()
    this.setState({hpl:respVal7})
    const response8 = await fetch(BASE_URL+'filenames/fundermax')
    const respVal8= await response8.json()
    this.setState({fundermax:respVal8})
    const response9 = await fetch(BASE_URL+'filenames/wpcbaffleceilings')
    const respVal9= await response9.json()
    this.setState({wpcbaffleceilings:respVal9})
    const response10 = await fetch(BASE_URL+'filenames/soffitpanels')
    const respVal10= await response10.json()
    this.setState({soffitpanels:respVal10})
   }
  render() {
    const photos =  getImages("newarrivals",this.state.newarrival);
    const d3_panels = getImages("3d_panels",this.state.dpanels);
    const fabio = getImages("fabio",this.state.fabio);
    const flooring = getImages("floorings",this.state.floorings);
    const indoorlouvers = getImages("indoorwpclouvers",this.state.indoorwpclouvers);
    const outdoorlouvers = getImages("outdoorwpclouvers",this.state.outdoorwpclouvers);
    const hpl = getImages("hpl",this.state.hpl);
    const fundermax = getImages("fundermax",this.state.fundermax);
    const wpcbaffleceilings = getImages("wpcbaffleceilings",this.state.wpcbaffleceilings);
    const soffitpanels = getImages("soffitpanels",this.state.soffitpanels);
    return (
      <div style={{ backgroundImage:`url(${BASE_URL+"download/general/bg"})` }}>
        <Header />
        <MenuBar />
        <center>
        <h2 style={{ color: "white" }}>New Arrivals</h2>
        <h3 style={{ color: "white" }}> WPC Baffle Ceilings</h3>
        <Gallery
          photos={wpcbaffleceilings}
          enableLightbox={false}
          enableImageSelection={true}
          onClickThumbnail={this.onSelectImage}
          thumbnailstyle={this.styleSmall}
        />
        <h3 style={{ color: "white" }}>Soffit Panels</h3>
        <Gallery
          photos={soffitpanels}
          enableLightbox={false}
          enableImageSelection={true}
          onClickThumbnail={this.onSelectImage}
          thumbnailstyle={this.styleSmall}
        />
        <h3 style={{ color: "white" }}>Indoor WPC Louvers</h3>
        <Gallery
          photos={indoorlouvers}
          enableLightbox={false}
          enableImageSelection={true}
          onClickThumbnail={this.onSelectImage}
          thumbnailstyle={this.styleSmall}
        />
        <h3 style={{ color: "white" }}>Outdoor WPC Louvers</h3>
        <Gallery
          photos={outdoorlouvers}
          enableLightbox={false}
          enableImageSelection={true}
          onClickThumbnail={this.onSelectImage}
          thumbnailstyle={this.styleSmall}
        />
        <h3 style={{ color: "white" }}>HPL</h3>
        <Gallery
          photos={hpl}
          enableLightbox={false}
          enableImageSelection={true}
          onClickThumbnail={this.onSelectImage}
          thumbnailstyle={this.styleSmall}
        />
        <h3 style={{ color: "white" }}>Fundermax</h3>
        <Gallery
          photos={fundermax}
          enableLightbox={false}
          enableImageSelection={true}
          onClickThumbnail={this.onSelectImage}
          thumbnailstyle={this.styleSmall}
        />


        <h3 style={{ color: "white" }}>3D Panels</h3>
        <Gallery
          photos={d3_panels}
          enableLightbox={false}
          enableImageSelection={true}
          onClickThumbnail={this.onSelectImage}
          thumbnailstyle={this.styleSmall}
        />

        <h3 style={{ color: "white" }}>Floorings</h3>
        <Gallery
          photos={flooring}
          enableLightbox={false}
          enableImageSelection={true}
          onClickThumbnail={this.onSelectImage}
          thumbnailstyle={this.styleSmall}
        />

        <h3 style={{ color: "white" }}>Wallpapers</h3>
        <Gallery
          photos={fabio}
          enableLightbox={false}
          enableImageSelection={true}
          onClickThumbnail={this.onSelectImage}
          thumbnailstyle={this.styleSmall}
        />

        <h3 style={{ color: "white" }}> Wooden & Gym Floorings</h3>
        <Gallery
          photos={photos}
          enableLightbox={false}
          enableImageSelection={true}
          onClickThumbnail={this.onSelectImage}
          thumbnailstyle={this.styleSmall}
        />
        </center>
        <div>&nbsp;</div>
        <div>&nbsp;</div>

        <SocialMediaLinks />
        <div>&nbsp;</div>
      </div>
    );
  }
}
export default NewProducts;
