import React, { Component } from "react";
import { Card,ListGroup } from "react-bootstrap";
import {BASE_URL} from "./utility";
import CardDeck from "react-bootstrap/CardDeck";
class Contact extends Component {
  state = {};
  render() {
    return (
      <div style={{ backgroundImage:`url(${BASE_URL+"download/general/bg"})` }}>
        <br></br>
        <CardDeck>
        <Card style={{ color: "black" }}>
          <Card.Body>
            <Card.Title>Phone</Card.Title>
            <ListGroup variant="flush">
              <ListGroup.Item>+91-9818428983</ListGroup.Item>
              <ListGroup.Item>+91-8851380981</ListGroup.Item>
            </ListGroup>
          </Card.Body>
        </Card>
        <hr></hr>
        <Card style={{ color: "black" }}>
          <Card.Body>
            <Card.Title>Email</Card.Title>
            <ListGroup variant="flush">
              <ListGroup.Item>rainbowtouchindia@gmail.com</ListGroup.Item>
              <ListGroup.Item>sidhantrout23@gmail.com</ListGroup.Item>
            </ListGroup>
          </Card.Body>
        </Card>
        <hr></hr>
        <Card style={{ color: "black" }}>
          <Card.Body>
            <Card.Title>Address</Card.Title>
            F-711 ,Lado Sarai ,New Delhi -110074
          </Card.Body>
        </Card>
        </CardDeck>
        <hr></hr>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3505.406871120986!2d77.19212631440463!3d28.52748779557965!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d1fef9072db3d%3A0xe831674cdc799f2e!2sRainbow%20Touch!5e0!3m2!1sen!2sin!4v1608536549039!5m2!1sen!2sin"
          width="100%"
          height="450"
          aria-hidden="false"
          title="frame2"
          style={{ border: "0" ,borderRadius: "10px",overflow: "hidden"}}
        ></iframe>
     
      </div>
    );
  }
}

export default Contact;
