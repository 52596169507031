import React, { Component } from "react";
import MenuBar from "./navbar";
import SocialMediaLinks from "./socialmedialinks";
import Header from  './header';
import Gallery from "react-photo-gallery";
import {BASE_URL,getImages} from "./utility";
class Product extends Component {
  constructor() {
    super()
    this.state = {
      count: 0
    };
  }
  state = {};
  componentDidMount() {
    fetch(BASE_URL+'filenames/'+this.props.product, {
  method: 'get'
  }).then((response) => response.json())
  .then((responseJson) => { 
    this.setState({
       count: responseJson
     });
  })
   .catch((error) => {
     console.error(error);
   });
}
  render() {
    const photos = getImages(this.props.product,this.state.count);
    return (
      <div style={{ backgroundImage:`url(${BASE_URL+"download/general/bg"})` }}>
           <Header />
        <center>
          <MenuBar />
          <h2 style={{ color: "white" }}>{this.props.desc}</h2>
        </center>
        <Gallery
          photos={photos}
          enableLightbox={false}
          enableImageSelection={true}
          onClickThumbnail={this.onSelectImage}
          thumbnailstyle={this.styleSmall}
        />
        ;<div>&nbsp;</div>
        <div>&nbsp;</div>
        <SocialMediaLinks />
        <div>&nbsp;</div>
      </div>
    );
  }
}

export default Product;
