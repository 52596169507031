import React, { useState } from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Header from './header';
import SocialMediaLinks from "./socialmedialinks";
import MenuBar from "./navbar";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import swal from "sweetalert";
import axios from "axios";
import { BASE_URL, PRODUCTS_LIST, HSN_LIST, GST_LIST, PER_QTY_RATE_LIST, TAX_TYPE_LIST, PRODUCT_HSN_MAPPER } from "./utility";
import { Redirect } from 'react-router-dom';
const InvoiceForm = (props) => {
  const [buyerinputValue, setbuyerinputValue] = useState('');
  const [consigneeinputValue, setconsigneeinputValue] = useState('');
  const [buyerSuggestions, setbuyerSuggestions] = useState([]);
  const [consigneeSuggestions, setconsigneeSuggestions] = useState([]);
  const handlebuyerAddressInput = (e) => {
    const value = e.target.value;
    setbuyerinputValue(value);
    if (value.length >= 4) {
      fetchSuggestions("buyerAddress", value);
    } else {
      setbuyerSuggestions([]);
    }
  };
  const handleconsigneeAddressInput = (e) => {
    const value = e.target.value;
    setconsigneeinputValue(value);
    if (value.length >= 4) {
      fetchSuggestions("consigneeAddress", value);
    } else {
      setconsigneeSuggestions([]);
    }
  };
  const fetchSuggestions = async (field, query) => {
    try {
      const response = await axios.get(`${BASE_URL}getCustomerRecords/${field}/${query}`);
      if (field === 'buyerAddress') {
        setbuyerSuggestions(response.data);
      } else if (field === 'consigneeAddress') {
        setconsigneeSuggestions(response.data);
      }
    } catch (error) {
      console.error("Error fetching suggestions:", error);
    }
  };
  const handleSuggestionClick = (fieledtype, address) => {

    if (fieledtype === 'buyer') {
      setbuyerSuggestions([]);
      setbuyerinputValue(address);
      invoiceGeneration.buyerAddress = address;
    } else
      if (fieledtype === 'consignee') {
        setconsigneeSuggestions([]);
        setconsigneeinputValue(address);
        invoiceGeneration.consigneeAddress = address;
      }

  };
  const [invoiceGeneration, setInvoiceGeneration] = useState({
    consigneeAddress: '',
    buyerAddress: '',
    invoiceNo: '',
    orderDate: new Date(),
    referenceNumber: '',
    otherRefrences: '',
    buyerOrderNumber: '',
    buyerDate: new Date(),
    dispatchDocNo: '',
    deliveryNoteDate: new Date(),
    dispatchedThrough: '',
    destination: '',
    termsOfDelivery: '',
    productname1: '', hsn1: '', qty1: '', rate1: '', ratePerQty1: '', gst1: '', pcomment1: '',
    productname2: '', hsn2: '', qty2: '', rate2: '', ratePerQty2: '', gst2: '', pcomment2: '',
    productname3: '', hsn3: '', qty3: '', rate3: '', ratePerQty3: '', gst3: '', pcomment3: '',
    productname4: '', hsn4: '', qty4: '', rate4: '', ratePerQty4: '', gst4: '', pcomment4: '',
    productname5: '', hsn5: '', qty5: '', rate5: '', ratePerQty5: '', gst5: '', pcomment5: '',
    type: props.type, taxType: ''
  });

  const generateInvoice = (event) => {
    event.preventDefault();
    if(invoiceGeneration.buyerAddress == '')
    {
      invoiceGeneration.buyerAddress = buyerinputValue;
    }
    
    if(invoiceGeneration.consigneeAddress == '')
    {
      invoiceGeneration.consigneeAddress = consigneeinputValue;
    }
    console.log('Input data ' + JSON.stringify(invoiceGeneration));    
    if (validateInputs()) {
      fetch(BASE_URL + 'generateInvoice', {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(invoiceGeneration)
      })
        .then(res => res.blob())
        .then(blob => {
          var file = window.URL.createObjectURL(blob);
          window.location.assign(file);
        });
    }
  }

  const validateInputs = () => {
    if (invoiceGeneration.productname1 === '' || invoiceGeneration.hsn1 === '' || invoiceGeneration.gst1 === '' ||
      invoiceGeneration.qty1 === '' || invoiceGeneration.rate1 === '' || invoiceGeneration.ratePerQty1 === '') {
      swal("Atleast fill one product detail completely !!")
      return false;
    }
    if (invoiceGeneration.productname2 !== '') {
      if (invoiceGeneration.hsn2 === '' || invoiceGeneration.gst2 === '' ||
        invoiceGeneration.qty2 === '' || invoiceGeneration.rate2 === '' || invoiceGeneration.ratePerQty2 === '') {
        swal("Please fill second product detail completely !!")
        return false;
      }
    }
    if (invoiceGeneration.productname3 !== '') {
      if (invoiceGeneration.hsn3 === '' || invoiceGeneration.gst3 === '' ||
        invoiceGeneration.qty3 === '' || invoiceGeneration.rate3 === '' || invoiceGeneration.ratePerQty3 === '') {
        swal("Please fill third product detail completely !!")
        return false;
      }
    }
    if (invoiceGeneration.productname4 !== '') {
      if (invoiceGeneration.hsn4 === '' || invoiceGeneration.gst4 === '' ||
        invoiceGeneration.qty4 === '' || invoiceGeneration.rate4 === '' || invoiceGeneration.ratePerQty4 === '') {
        swal("Please fill 4th product detail completely !!")
        return false;
      }
    }
    if (invoiceGeneration.productname5 !== '') {
      if (invoiceGeneration.hsn5 === '' || invoiceGeneration.gst5 === '' ||
        invoiceGeneration.qty5 === '' || invoiceGeneration.rate5 === '' || invoiceGeneration.ratePerQty5 === '') {
        swal("Please fill 5th product detail completely !!")
        return false;
      }
    }

    return true;
  }

  const handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if (name.includes('productname')) {
      let matches = name.match(/(\d+)/);
      setInvoiceGeneration({ ...invoiceGeneration, [name]: value, ["hsn" + matches[0]]: PRODUCT_HSN_MAPPER[value] })
    } else {
      setInvoiceGeneration({ ...invoiceGeneration, [name]: value })
    }
  }
  const handleOrderDateInput = (e) => {
    const dateVal = e
    setInvoiceGeneration({ ...invoiceGeneration, orderDate: dateVal })
  }
  const handleBuyerDateInput = (e) => {
    const dateVal = e
    setInvoiceGeneration({ ...invoiceGeneration, buyerDate: dateVal })
  }
  const handleDeliveryNoteDateInput = (e) => {
    const dateVal = e
    setInvoiceGeneration({ ...invoiceGeneration, deliveryNoteDate: dateVal })
  }
  const myStyle = {
    color: "white",
    backgroundColor: "",
    padding: "5%",
    fontFamily: "Sans-Serif",
    width: '100%',
    margin: '0 auto'
  };
  if (!localStorage.getItem("token"))
    return <Redirect to='/auth' />
  else
    return (
      <div style={{ backgroundImage: `url(${BASE_URL + "download/general/bg"})` }}>
        <Header />
        <center>
          <MenuBar />
          <h2 style={{ color: "white" }}>{props.desc}</h2>
        </center>

        <div style={myStyle}>

          <Form onSubmit={generateInvoice}>
            <Form.Group className="mb-3">
              <Form.Label>Tax Type</Form.Label>
              <select className="form-control" value={invoiceGeneration.taxType} onChange={handleInput} name="taxType" id="taxType" required>
                {TAX_TYPE_LIST.map(item => {
                  return (<option key={item.value} value={item.value}>{item.text}</option>);
                })}
              </select>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Consignee Address</Form.Label>
              <Form.Control
                autoComplete="off"
                type="text"
                value={consigneeinputValue}
                onChange={handleconsigneeAddressInput}
                name="consigneeAddress"
                id="consigneeAddress"
                placeholder="Enter Consignee Address"
                required
              />
              {consigneeSuggestions.length > 0 && (
                <ol className="consigneesuggestions" style={{ backgroundColor: 'white', color: 'black', borderRadius: '10px' }} >
                  {consigneeSuggestions.map((address) => (
                    <li
                      key={address}
                      onClick={() => handleSuggestionClick("consignee", address)}
                    >
                      {address}
                    </li>
                  ))}
                </ol>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Invoice Number</Form.Label>
              <Form.Control autoComplete="off" type="text" value={invoiceGeneration.invoiceNo} onChange={handleInput} name="invoiceNo" id="invoiceNo" placeholder="Enter Invoice Number"  />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Buyer Address</Form.Label>
              <Form.Control
                autoComplete="off"
                type="text"
                value={buyerinputValue}
                onChange={handlebuyerAddressInput}
                name="buyerAddress"
                id="buyerAddress"
                placeholder="Enter Buyer Address"
                required
              />
              {buyerSuggestions.length > 0 && (
                <ol className="buyersuggestions" style={{ backgroundColor: 'white', color: 'black', borderRadius: '10px' }} >
                  {buyerSuggestions.map((address) => (
                    <li
                      key={address}
                      onClick={() => handleSuggestionClick("buyer", address)}
                    >
                      {address}
                    </li>
                  ))}
                </ol>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Date</Form.Label>
              <DatePicker autoComplete="off" dateformat="MM/dd/yyyy" selected={invoiceGeneration.orderDate} value={invoiceGeneration.orderDate} onChange={handleOrderDateInput} name="orderDate" id="orderDate" placeholder="Enter Date" required />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Reference No</Form.Label>
              <Form.Control autoComplete="off" type="text" value={invoiceGeneration.referenceNumber} onChange={handleInput} name="referenceNumber" id="referenceNumber" placeholder="Enter Reference No" required />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Other Refrences</Form.Label>
              <Form.Control autoComplete="off" type="text" value={invoiceGeneration.otherRefrences} onChange={handleInput} name="otherRefrences" id="otherRefrences" placeholder="Enter Other Refrences" />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Buyer Order No</Form.Label>
              <Form.Control autoComplete="off" type="text" value={invoiceGeneration.buyerOrderNumber} onChange={handleInput} name="buyerOrderNumber" id="buyerOrderNumber" placeholder="Enter Buyer Order No" />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Buyer Date</Form.Label>
              <DatePicker autoComplete="off" dateformat="MM/dd/yyyy" selected={invoiceGeneration.buyerDate} value={invoiceGeneration.buyerDate} onChange={handleBuyerDateInput} name="buyerDate" id="buyerDate" placeholder="Enter Buyer Date" required />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Dispatch Doc No</Form.Label>
              <Form.Control autoComplete="off" type="text" value={invoiceGeneration.dispatchDocNo} onChange={handleInput} name="dispatchDocNo" id="dispatchDocNo" placeholder="Enter Dispatch Doc No" />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Delivery Note Date</Form.Label>
              <DatePicker autoComplete="off" dateformat="MM/dd/yyyy" selected={invoiceGeneration.deliveryNoteDate} value={invoiceGeneration.deliveryNoteDate} onChange={handleDeliveryNoteDateInput} name="deliveryNoteDate" id="deliveryNoteDate" placeholder="Enter Delivery Note Date" required />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Dispatched Through</Form.Label>
              <Form.Control autoComplete="off" type="text" value={invoiceGeneration.dispatchedThrough} onChange={handleInput} name="dispatchedThrough" id="dispatchedThrough" placeholder="Enter Dispatched Through" required />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Destination</Form.Label>
              <Form.Control autoComplete="off" type="text" value={invoiceGeneration.destination} onChange={handleInput} name="destination" id="destination" placeholder="Enter Destination" required />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Terms of Delivery</Form.Label>
              <Form.Control autoComplete="off" type="textarea" value={invoiceGeneration.termsOfDelivery} onChange={handleInput} name="termsOfDelivery" id="termsOfDelivery" placeholder="Enter Terms of Delivery" />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label >Select Products</Form.Label>
              <Form inline>
                <Form.Group>
                  <select style={{ width: '150px' }} className="form-control" value={invoiceGeneration.productname1} onChange={handleInput} name="productname1" id="productname1" required>
                    {PRODUCTS_LIST.map(item => {
                      return (<option key={item.value} value={item.value}>{item.text}</option>);
                    })}
                  </select>
                  <Form.Control style={{ width: '150px' }} autoComplete="off" type="text" value={invoiceGeneration.pcomment1} onChange={handleInput} name="pcomment1" id="pcomment1" placeholder="Description" />

                  <select style={{ width: '150px' }} className="form-control" value={invoiceGeneration.hsn1} onChange={handleInput} name="hsn1" id="hsn1" placeholder="Enter HSN/SAC" required>
                    {HSN_LIST.map(item => {
                      return (<option key={item.value} value={item.value}>{item.text}</option>);
                    })}
                  </select>

                  <Form.Control style={{ width: '150px' }} autoComplete="off" type="number" min="0" value={invoiceGeneration.qty1} onChange={handleInput} name="qty1" id="qty1" placeholder="Enter Quantity" required />

                  <Form.Control style={{ width: '150px' }} autoComplete="off" type="number" min="0" value={invoiceGeneration.rate1} onChange={handleInput} name="rate1" id="rate1" placeholder="Enter Rate" required />

                  <select style={{ width: '150px' }} className="form-control" value={invoiceGeneration.ratePerQty1} onChange={handleInput} name="ratePerQty1" id="ratePerQty1" required>
                    {PER_QTY_RATE_LIST.map(item => {
                      return (<option key={item.value} value={item.value}>{item.text}</option>);
                    })}
                  </select>

                  <select style={{ width: '150px' }} className="form-control" value={invoiceGeneration.gst1} onChange={handleInput} name="gst1" id="gst1" required>
                    {GST_LIST.map(item => {
                      return (<option key={item.value} value={item.value}>{item.text}</option>);
                    })}
                  </select>
                </Form.Group>
              </Form>

              <Form inline>
                <Form.Group>
                  <select style={{ width: '150px' }} className="form-control" value={invoiceGeneration.productname2} onChange={handleInput} name="productname2" id="productname2">
                    {PRODUCTS_LIST.map(item => {
                      return (<option key={item.value} value={item.value}>{item.text}</option>);
                    })}
                  </select>
                  <Form.Control style={{ width: '150px' }} autoComplete="off" type="text" value={invoiceGeneration.pcomment2} onChange={handleInput} name="pcomment2" id="pcomment2" placeholder="Description" />
                  <select style={{ width: '150px' }} className="form-control" value={invoiceGeneration.hsn2} onChange={handleInput} name="hsn2" id="hsn2" placeholder="Enter HSN/SAC" >
                    {HSN_LIST.map(item => {
                      return (<option key={item.value} value={item.value}>{item.text}</option>);
                    })}
                  </select>

                  <Form.Control style={{ width: '150px' }} autoComplete="off" type="number" min="0" value={invoiceGeneration.qty2} onChange={handleInput} name="qty2" id="qty2" placeholder="Enter Quantity " />

                  <Form.Control style={{ width: '150px' }} autoComplete="off" type="number" min="0" value={invoiceGeneration.rate2} onChange={handleInput} name="rate2" id="rate2" placeholder="Enter Rate" />


                  <select style={{ width: '150px' }} className="form-control" value={invoiceGeneration.ratePerQty2} onChange={handleInput} name="ratePerQty2" id="ratePerQty2" >
                    {PER_QTY_RATE_LIST.map(item => {
                      return (<option key={item.value} value={item.value}>{item.text}</option>);
                    })}
                  </select>
                  <select style={{ width: '150px' }} className="form-control" value={invoiceGeneration.gst2} onChange={handleInput} name="gst2" id="gst2" >
                    {GST_LIST.map(item => {
                      return (<option key={item.value} value={item.value}>{item.text}</option>);
                    })}
                  </select>
                </Form.Group>
              </Form>
              <Form inline>
                <Form.Group>
                  <select style={{ width: '150px' }} className="form-control" value={invoiceGeneration.productname3} onChange={handleInput} name="productname3" id="productname3">
                    {PRODUCTS_LIST.map(item => {
                      return (<option key={item.value} value={item.value}>{item.text}</option>);
                    })}
                  </select>
                  <Form.Control style={{ width: '150px' }} autoComplete="off" type="text" value={invoiceGeneration.pcomment3} onChange={handleInput} name="pcomment3" id="pcomment3" placeholder="Description" />
                  <select style={{ width: '150px' }} className="form-control" value={invoiceGeneration.hsn3} onChange={handleInput} name="hsn3" id="hsn3" placeholder="Enter HSN/SAC" >
                    {HSN_LIST.map(item => {
                      return (<option key={item.value} value={item.value}>{item.text}</option>);
                    })}
                  </select>
                  <Form.Control style={{ width: '150px' }} autoComplete="off" type="number" min="0" value={invoiceGeneration.qty3} onChange={handleInput} name="qty3" id="qty3" placeholder="Enter Quantity " />

                  <Form.Control style={{ width: '150px' }} autoComplete="off" type="number" min="0" value={invoiceGeneration.rate3} onChange={handleInput} name="rate3" id="rate3" placeholder="Enter Rate" />

                  <select style={{ width: '150px' }} className="form-control" value={invoiceGeneration.ratePerQty3} onChange={handleInput} name="ratePerQty3" id="ratePerQty3" >
                    {PER_QTY_RATE_LIST.map(item => {
                      return (<option key={item.value} value={item.value}>{item.text}</option>);
                    })}
                  </select>
                  <select style={{ width: '150px' }} className="form-control" value={invoiceGeneration.gst3} onChange={handleInput} name="gst3" id="gst3" >
                    {GST_LIST.map(item => {
                      return (<option key={item.value} value={item.value}>{item.text}</option>);
                    })}
                  </select>
                </Form.Group>
              </Form>
              <Form inline>
                <Form.Group>
                  <select style={{ width: '150px' }} className="form-control" value={invoiceGeneration.productname4} onChange={handleInput} name="productname4" id="productname4">
                    {PRODUCTS_LIST.map(item => {
                      return (<option key={item.value} value={item.value}>{item.text}</option>);
                    })}
                  </select>
                  <Form.Control style={{ width: '150px' }} autoComplete="off" type="text" value={invoiceGeneration.pcomment4} onChange={handleInput} name="pcomment4" id="pcomment4" placeholder="Description" />
                  <select style={{ width: '150px' }} className="form-control" value={invoiceGeneration.hsn4} onChange={handleInput} name="hsn4" id="hsn4" placeholder="Enter HSN/SAC" >
                    {HSN_LIST.map(item => {
                      return (<option key={item.value} value={item.value}>{item.text}</option>);
                    })}
                  </select>

                  <Form.Control style={{ width: '150px' }} autoComplete="off" type="number" min="0" value={invoiceGeneration.qty4} onChange={handleInput} name="qty4" id="qty4" placeholder="Enter Quantity " />

                  <Form.Control style={{ width: '150px' }} autoComplete="off" type="number" min="0" value={invoiceGeneration.rate4} onChange={handleInput} name="rate4" id="rate4" placeholder="Enter Rate" />

                  <select style={{ width: '150px' }} className="form-control" value={invoiceGeneration.ratePerQty4} onChange={handleInput} name="ratePerQty4" id="ratePerQty4" >
                    {PER_QTY_RATE_LIST.map(item => {
                      return (<option key={item.value} value={item.value}>{item.text}</option>);
                    })}
                  </select>
                  <select style={{ width: '150px' }} className="form-control" value={invoiceGeneration.gst4} onChange={handleInput} name="gst4" id="gst4" >
                    {GST_LIST.map(item => {
                      return (<option key={item.value} value={item.value}>{item.text}</option>);
                    })}
                  </select>
                </Form.Group>
              </Form>
              <Form inline>
                <Form.Group>
                  <select style={{ width: '150px' }} className="form-control" value={invoiceGeneration.productname5} onChange={handleInput} name="productname5" id="productname5">
                    {PRODUCTS_LIST.map(item => {
                      return (<option key={item.value} value={item.value}>{item.text}</option>);
                    })}
                  </select>
                  <Form.Control style={{ width: '150px' }} autoComplete="off" type="text" value={invoiceGeneration.pcomment5} onChange={handleInput} name="pcomment5" id="pcomment5" placeholder="Description" />
                  <select style={{ width: '150px' }} className="form-control" value={invoiceGeneration.hsn5} onChange={handleInput} name="hsn5" id="hsn5" placeholder="Enter HSN/SAC" >
                    {HSN_LIST.map(item => {
                      return (<option key={item.value} value={item.value}>{item.text}</option>);
                    })}
                  </select>

                  <Form.Control style={{ width: '150px' }} autoComplete="off" type="number" min="0" value={invoiceGeneration.qty5} onChange={handleInput} name="qty5" id="qty5" placeholder="Enter Quantity " />

                  <Form.Control style={{ width: '150px' }} autoComplete="off" type="number" min="0" value={invoiceGeneration.rate5} onChange={handleInput} name="rate5" id="rate5" placeholder="Enter Rate" />

                  <select style={{ width: '150px' }} className="form-control" value={invoiceGeneration.ratePerQty5} onChange={handleInput} name="ratePerQty5" id="ratePerQty5" >
                    {PER_QTY_RATE_LIST.map(item => {
                      return (<option key={item.value} value={item.value}>{item.text}</option>);
                    })}
                  </select>
                  <select style={{ width: '150px' }} className="form-control" value={invoiceGeneration.gst5} onChange={handleInput} name="gst5" id="gst5" >
                    {GST_LIST.map(item => {
                      return (<option key={item.value} value={item.value}>{item.text}</option>);
                    })}
                  </select>
                </Form.Group>
              </Form>
            </Form.Group>
            <Button variant="primary" autoComplete="off" type="submit">
              Generate Invoice
            </Button>
          </Form>
          <div>&nbsp;</div>
          <div>&nbsp;</div>
          <SocialMediaLinks />
          <div>&nbsp;</div>
        </div>
      </div>
    );
}
export default InvoiceForm;