import axios from 'axios';
import React, { Component } from 'react';
import MenuBar from "./navbar";
import SocialMediaLinks from "./socialmedialinks";
import Header from './header';
import { Redirect } from 'react-router-dom';
import { BASE_URL, PRODUCT_UPLOADS } from "./utility";
import swal from 'sweetalert';
class FileUploadComponent extends Component {

	state = {
		showInput: false,
		selectedFile: null
	};

	API_BASE_URL = BASE_URL + 'uploadfiles/';
	onFileChange = event => {
		this.setState({ selectedFile: event.target.files[0] });
	};

	handleProductChange = (event) => {
		this.setState({ showInput: true });
	  };

	onFileUpload = () => {
		let productType = document.getElementById('productType').value;
		const formData = new FormData();
		formData.append(
			"file",
			this.state.selectedFile,
			this.state.selectedFile.name
		);
		axios.post(this.API_BASE_URL + productType + "/" + this.state.selectedFile.name, formData)
			.then((response) => {
				swal(response.data);
			}, (error) => {
				console.log(error);
			});
	};
	fileData = () => {
		if (this.state.selectedFile) {
			return (
				<center>
					<div>&nbsp;</div>
					<div>&nbsp;</div>
					<div style={{ color: "white", backgroundColor: "grey", width: "40%" ,borderRadius:"20px"}}>
						<h4>File Details:</h4>
						<p>File Name: {this.state.selectedFile.name}</p>

						<p>File Type: {this.state.selectedFile.type}</p>

						<p>
							Last Modified:{" "}
							{this.state.selectedFile.lastModifiedDate.toDateString()}
						</p>
						<button id="uploadBtn" onClick={this.onFileUpload} style={{ backgroundColor: "green",borderRadius:"10px" }}>
								Upload !!
						</button>
					</div>
				</center>
			);
		} else {
			return (
				<center>
					<div>
						<br />
					</div>
				</center>
			);
		}
	};

	render() {
		const { showInput} = this.state;
		if (!localStorage.getItem("token"))
			return <Redirect to='/auth' />
		else
			return (
				<div style={{ backgroundImage: `url(${BASE_URL + "download/general/bg"})` }}>
					<Header />

					<center>
						<h4 style={{ color: "white" }}>Upload Files </h4>
						<MenuBar />
					</center>
					<br></br>
					<center>
						<div style={{ color: "white", backgroundColor: "grey", width: "40%" ,borderRadius:"20px"}} >
							<select onChange={this.handleProductChange} style={{ color: "white", backgroundColor: "grey",borderRadius:"20px"}} className="form-control" name="productType" id="productType" required>
							{PRODUCT_UPLOADS.map(item => {
								return (<option key={item.value} value={item.value}>{item.text}</option>);
							})}
							</select>
							{showInput && <input type="file" onChange={this.onFileChange} />}
						</div>
					</center>
					{this.fileData()}
					<div>&nbsp;</div>
					<SocialMediaLinks />
					<div>&nbsp;</div>
				</div>
			);
	}
}

export default FileUploadComponent;
