import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import React, { useState } from "react";
import MailClient from "./mailclient";
function Enquiry() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
 
  return (
    <>
      <Button className="btn btn-primary" onClick={handleShow}>
        Request a Callback !!
      </Button>

      <Modal show={show} onHide={handleClose} animation={true}>
        <Modal.Header closeButton>
          <Modal.Title>Callback Details</Modal.Title>
        </Modal.Header>
        <Modal.Body >
            <MailClient/>
        </Modal.Body>
        <Modal.Footer>
          <Button style={{ display: "block", margin: "auto" }} variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Enquiry;
