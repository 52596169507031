import React, { Component } from "react";
import MenuBar from "./navbar";
import Carousel from "react-bootstrap/Carousel";
import SocialMediaLinks from "./socialmedialinks";
import Header from './header';
import { BASE_URL, carouselItems } from "./utility";
import Card from "react-bootstrap/Card";
import CardDeck from "react-bootstrap/CardDeck";
import { Button } from "react-bootstrap";
class Home extends Component {
  state = {};
  render() {
    return (
      <div style={{ backgroundImage: `url(${BASE_URL + "download/general/bg"})` }}>
        <Header />
        <MenuBar />
        <Carousel>
          {carouselItems.map((item) => (
            <Carousel.Item key={item.id} interval={1000}>
              <img
                height={500}
                className="d-block w-100"
                src={item.image}
                alt={item.caption}
                style={{ borderRadius: '45px' }}
              />
              <Carousel.Caption>
                <h3>{item.caption}</h3>
              </Carousel.Caption>
            </Carousel.Item>
          ))}
        </Carousel>
        <hr></hr>

        <h3 style={{ color: "white" }}>New Arrivals</h3>
        <CardDeck>
          <Card className="mb-4">
            <Card.Img variant="top" src={BASE_URL + "download/verticalgarden/verticalgarden1"} width={120} height={180} />
            <Card.Body>
              <Card.Title>Vertical Gardens</Card.Title>
              <Card.Text>Exterior and Interior Vertical Gardens</Card.Text>
              <Button variant="primary" href="/verticalgarden">
                Explore
              </Button>
            </Card.Body>
          </Card>

          <Card className="mb-4">
            <Card.Img variant="top" src={BASE_URL + "download/foampanels/foampanels1"} width={120} height={180} />
            <Card.Body>
              <Card.Title>Foam Panels</Card.Title>
              <Card.Text>Best foam Panels</Card.Text>
              <Button variant="primary" href="/foampanels">
                Explore
              </Button>
            </Card.Body>
          </Card>

          <Card style={{ borderRadius: '10px' }} className="mb-4">
            <Card.Img variant="top" src={BASE_URL + "download/fundermax/fundermax1"} width={120} height={180} />
            <Card.Body>
              <Card.Title>Fundermax</Card.Title>
              <Card.Text>Best Fundermax</Card.Text>
              <Button variant="primary" href="/fundermax">
                Explore
              </Button>
            </Card.Body>
          </Card>

          <Card style={{ borderRadius: '10px' }} className="mb-4">
            <Card.Img variant="top" src={BASE_URL + "download/aluminiumlouverpanels/aluminiumlouverpanels2"} width={120} height={180} />
            <Card.Body>
              <Card.Title>Aluminium Louver Panels</Card.Title>
              <Card.Text>Best Aluminium Louver Panels</Card.Text>
              <Button variant="primary" href="/aluminiumlouverpanels">
                Explore
              </Button>
            </Card.Body>
          </Card>

          <Card style={{ borderRadius: '10px' }} className="mb-4">
            <Card.Img variant="top" src={BASE_URL + "download/indoorwpclouvers/indoorwpclouvers1"} width={120} height={180} />
            <Card.Body>
              <Card.Title>Indoor Wpc Louvers</Card.Title>
              <Card.Text>Best Indoor Wpc Louvers</Card.Text>
              <Button variant="primary" href="/indoorwpclouvers">
                Explore
              </Button>
            </Card.Body>
          </Card>

        </CardDeck>

        <CardDeck>
          <Card style={{ borderRadius: '10px' }} className="mb-4">
            <Card.Img variant="top" src={BASE_URL + "download/outdoorwpclouvers/outdoorwpclouvers1"} width={120} height={180} />
            <Card.Body>
              <Card.Title>Outdoor WPC Louvers</Card.Title>
              <Card.Text>Best Outdoor wpc louvers</Card.Text>
              <Button variant="primary" href="/outdoorwpclouvers">
                Explore
              </Button>
            </Card.Body>
          </Card>
          <Card style={{ borderRadius: '10px' }} className="mb-4">
            <Card.Img variant="top" src={BASE_URL + "download/soffitpanels/soffitpanels1"} width={120} height={180} />
            <Card.Body>
              <Card.Title>Soffitpanels</Card.Title>
              <Card.Text>Best Soffitpanels</Card.Text>
              <Button variant="primary" href="/soffitpanels">
                Explore
              </Button>
            </Card.Body>
          </Card>

          <Card style={{ borderRadius: '10px' }} className="mb-4">
            <Card.Img variant="top" src={BASE_URL + "download/wpcbaffleceilings/wpcbaffleceilings1"} width={120} height={180} />
            <Card.Body>
              <Card.Title>WPC Baffle Ceilings</Card.Title>
              <Card.Text>Best WPC Baffle Ceilings</Card.Text>
              <Button variant="primary" href="/wpcbaffleceilings">
                Explore
              </Button>
            </Card.Body>
          </Card>

          <Card style={{ borderRadius: '10px' }} className="mb-4">
            <Card.Img variant="top" src={BASE_URL + "download/hpl/hpl1"} width={120} height={180} />
            <Card.Body>
              <Card.Title>HPL</Card.Title>
              <Card.Text>Best HPL</Card.Text>
              <Button variant="primary" href="/hpl">
                Explore
              </Button>
            </Card.Body>
          </Card>

          <Card style={{ borderRadius: '10px' }} className="mb-4">
            <Card.Img variant="top" src={BASE_URL + "download/agrass/agrass1"} width={120} height={180} />
            <Card.Body>
              <Card.Title>Artificial Grass</Card.Title>
              <Card.Text>Best quality artificial grass</Card.Text>
              <Button variant="primary" href="/agrass">
                Explore
              </Button>
            </Card.Body>
          </Card>
        </CardDeck>

        <h3 style={{ color: "white" }}>Trending</h3>
        <CardDeck>
          <Card style={{ borderRadius: '10px' }} className="mb-4">
            <Card.Img variant="top" src={BASE_URL + "download/wallpapers/wall1"} width={120} height={180} />
            <Card.Body>
              <Card.Title>Wallpapers</Card.Title>
              <Card.Text>All kinds of imported wallpapers.</Card.Text>
              <Button variant="primary" href="/wallpapers">
                Explore
              </Button>
            </Card.Body>
          </Card>

          <Card style={{ borderRadius: '10px' }} className="mb-4">
            <Card.Img variant="top" src={BASE_URL + "download/woodenfloorings/wood1"} width={120} height={180} />
            <Card.Body>
              <Card.Title>Wooden Floorings</Card.Title>
              <Card.Text>Best quality wooden floorings</Card.Text>
              <Button variant="primary" href="/woodenfloorings">
                Explore
              </Button>
            </Card.Body>
          </Card>

          <Card style={{ borderRadius: '10px' }} className="mb-4">
            <Card.Img variant="top" src={BASE_URL + "download/claddings/cladding1"} width={120} height={180} />
            <Card.Body>
              <Card.Title>Claddings</Card.Title>
              <Card.Text>All kinds of wall claddings</Card.Text>
              <Button variant="primary" href="/claddings">
                Explore
              </Button>
            </Card.Body>
          </Card>

          <Card style={{ borderRadius: '10px' }} className="mb-4">
            <Card.Img variant="top" src={BASE_URL + "download/ceilings/ceiling1"} width={120} height={180} />
            <Card.Body>
              <Card.Title>Ceilings</Card.Title>
              <Card.Text>All kind of false ceilings </Card.Text>
              <Button variant="primary" href="/ceilings">
                Explore
              </Button>
            </Card.Body>
          </Card>

          <Card style={{ borderRadius: '10px' }} className="mb-4">
            <Card.Img variant="top" src={BASE_URL + "download/awnings/awning1"} width={120} height={180} />
            <Card.Body>
              <Card.Title>Awnings</Card.Title>
              <Card.Text>Wall and window Awnings </Card.Text>
              <Button variant="primary" href="/awnings">
                Explore
              </Button>
            </Card.Body>
          </Card>
        </CardDeck>
        <h3 style={{ color: "white" }}>Best Selling</h3>
        <CardDeck>

          <Card className="mb-4">
            <Card.Img variant="top" src={BASE_URL + "download/acousticbaffleceilings/acousticbaffleceilings1"} width={120} height={180} />
            <Card.Body>
              <Card.Title>Acoustic Baffle Ceilings</Card.Title>
              <Card.Text>All kinds of Acoustic Baffle Ceilings</Card.Text>
              <Button variant="primary" href="/acousticbaffleceilings">
                Explore
              </Button>
            </Card.Body>
          </Card>

          <Card style={{ borderRadius: '10px' }} className="mb-4">
            <Card.Img variant="top" src={BASE_URL + "download/wall2wallc/wall2wall1"} width={120} height={180} />
            <Card.Body>
              <Card.Title>Wall 2 Wall Carpets</Card.Title>
              <Card.Text>Imported wall-2-wall carpets</Card.Text>
              <Button variant="primary" href="/wall2wall">
                Explore
              </Button>
            </Card.Body>
          </Card>

          <Card style={{ borderRadius: '10px' }} className="mb-4">
            <Card.Img variant="top" src={BASE_URL + "download/blinds/blinds1"} width={120} height={180} />
            <Card.Body>
              <Card.Title>Blinds</Card.Title>
              <Card.Text>
                All kinds of blinds available (wooden blinds) .
              </Card.Text>
              <Button variant="primary" href="/blinds">
                Explore
              </Button>
            </Card.Body>
          </Card>

          <Card style={{ borderRadius: '10px' }} className="mb-4">
            <Card.Img variant="top" src={BASE_URL + "download/solar/solar1"} width={120} height={180} />
            <Card.Body>
              <Card.Title>Sun Films</Card.Title>
              <Card.Text>For best sun shield </Card.Text>
              <Button variant="primary" href="/solar">
                Explore
              </Button>
            </Card.Body>
          </Card>



          <Card style={{ borderRadius: '10px' }} className="mb-4">
            <Card.Img variant="top" src={BASE_URL + "download/agrass/agrass1"} width={120} height={180} />
            <Card.Body>
              <Card.Title>Artificial Grass</Card.Title>
              <Card.Text>Best quality artificial grass</Card.Text>
              <Button variant="primary" href="/agrass">
                Explore
              </Button>
            </Card.Body>
          </Card>
        </CardDeck>

        <CardDeck>
          <Card style={{ borderRadius: '10px' }} className="mb-4">
            <Card.Img variant="top" src={BASE_URL + "download/doormats/doormats1"} width={120} height={180} />
            <Card.Body>
              <Card.Title>Door Mats</Card.Title>
              <Card.Text>Imported door mats</Card.Text>
              <Button variant="primary" href="/doormats">
                Explore
              </Button>
            </Card.Body>
          </Card>
          <Card style={{ borderRadius: '10px' }} className="mb-4">
            <Card.Img variant="top" src={BASE_URL + "download/pvc/pvc1"} width={120} height={180} />
            <Card.Body>
              <Card.Title>PVC Panels</Card.Title>
              <Card.Text>All kinds of pvc panels</Card.Text>
              <Button variant="primary" href="/pvcpanels">
                Explore
              </Button>
            </Card.Body>
          </Card>
          <Card style={{ borderRadius: '10px' }} className="mb-4">
            <Card.Img variant="top" src={BASE_URL + "download/nexa/nexa1"} width={120} height={180} />
            <Card.Body>
              <Card.Title>Nexa</Card.Title>
              <Card.Text>All Nexa Products</Card.Text>
              <Button variant="primary" href="/nexacarpets">
                Explore
              </Button>
            </Card.Body>
          </Card>
          <Card style={{ borderRadius: '10px' }} className="mb-4">
            <Card.Img
              variant="top"
              src={BASE_URL + "download/carpettiles/carpettiles1"}
              width={150}
              height={200}
            />
            <Card.Body>
              <Card.Title>Carpet Tiles</Card.Title>
              <Card.Text>Imported modular carpet tiles</Card.Text>
              <Button variant="primary" href="/modulercarpets">
                Explore
              </Button>
            </Card.Body>
          </Card>

          <Card style={{ borderRadius: '10px' }} className="mb-4">
            <Card.Img variant="top" src={BASE_URL + "download/gym/gym1"} width={120} height={180} />
            <Card.Body>
              <Card.Title>Gym Floorings</Card.Title>
              <Card.Text>Best quality gym floorings</Card.Text>
              <Button variant="primary" href="/gymfloorings">
                Explore
              </Button>
            </Card.Body>
          </Card>
        </CardDeck>

        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3505.406871120986!2d77.19212631440463!3d28.52748779557965!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d1fef9072db3d%3A0xe831674cdc799f2e!2sRainbow%20Touch!5e0!3m2!1sen!2sin!4v1608536549039!5m2!1sen!2sin"
          width="100%"
          height="450"
          aria-hidden="false"
          style={{ border: "0", borderRadius: "10px", overflow: "hidden" }}
          title="frame1"
        ></iframe>
        <hr></hr>
        <SocialMediaLinks />
        <div>&nbsp;</div>
      </div>
    );
  }
}

export default Home;
