import React, { Component } from "react";
import {BASE_URL} from "./utility";
class Header extends Component {
  state = {};
  render() {
    return (
      <div style={{ color: "white", display: "inline-block" }}>
        <h1>
          &nbsp;
          <img height={50} width={250} src={BASE_URL+"download/general/name"} alt="Name" /> 
        </h1>
      </div>
    );
  }
}

export default Header;
