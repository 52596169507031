export const getImages = function (basepath, filenames) {
  const photos = []
  for (let i in filenames) {
    let obj = {}
    obj["src"] =BASE_URL+"download/"+basepath+'/'+filenames[i];
    obj["width"] = 4;
    obj["height"] = 3;
    photos.push(obj)
  }
  return photos;
}
export const BASE_URL = "http://rainbowtouchindia.com/rtiservices/";
//export const BASE_URL = "http://127.0.0.1:5000/";

export const PRODUCTS_LIST = [
  { value: '', text: 'Select Product' },
  { value: 'Sun Film', text: 'Sun Film' },
  { value: 'WPC Tube/WPC Pond', text: 'WPC Tube/WPC Pond' },
  { value: 'Metal Keel', text: 'Metal Keel' },
  { value: 'LAM Sheets 6mm', text: 'LAM Sheets 6mm' },
  { value: 'WPC Exterior', text: 'WPC Exterior' },
  { value: 'WPC Dekin', text: 'WPC Dekin' },
  { value: 'WPC L Profile', text: 'WPC L Profile' },
  { value: 'Deck Clip SS', text: 'Deck Clip SS' },
  { value: 'WPC Panel', text: 'WPC Panel' },
  { value: 'Clip Wall Panel', text: 'Clip Wall Panel' },
  { value: 'Laminate Flooring', text: 'Laminate Flooring' },
  { value: 'Beading', text: 'Beading' },
  { value: 'Skirting', text: 'Skirting' },
  { value: 'Wallpaper', text: 'Wallpaper' },
  { value: 'Profile', text: 'Profile' },
  { value: 'Carpet Tile', text: 'Carpet Tile' },
  { value: 'Wall to Wall Carpets', text: 'Wall to Wall Carpets' },
  { value: 'Aluminium Extruded', text: 'Aluminium Extruded' },
  { value: 'WPC Baffle Ceiling', text: 'WPC Baffle Ceiling' },
  { value: 'Suspension Bar', text: 'Suspension Bar' },
  { value: 'Screw', text: 'Screw' },
  { value: 'Roller Blinds', text: 'Roller Blinds' },
  { value: 'Metal Rod', text: 'Metal Rod' },
  { value: 'Blinds', text: 'Blinds' },
  { value: 'Freight & Cartage Charges', text: 'Freight & Cartage Charges' },
  { value: 'Packing Charges', text: 'Packing Charges' },
  { value: 'Carpets', text: 'Carpets' },
  { value: 'Catalogue', text: 'Catalogue' },
  { value: 'Plastic Moulding', text: 'Plastic Moulding' },
  { value: 'Aluminium Section', text: 'Aluminium Section' },
  { value: 'Rubber Floor', text: 'Rubber Floor' },
  { value: 'SPC Flooring', text: 'SPC Flooring' },
  { value: 'SPC Skirting/Profile/Beading', text: 'SPC Skirting/Profile/Beading' },
  { value: 'PVC Flooring/Tiles', text: 'PVC Flooring/Tiles' },
  { value: 'Artificial Grass', text: 'Artificial Grass' },
  { value: 'PVC Panel', text: 'PVC Panel' },
  { value: 'IPE Wood', text: 'IPE Wood' },
  { value: 'Kumaru Wood', text: 'Kumaru Wood' },
  { value: 'Awning/Canopy', text: 'Awning/Canopy' },
  { value: 'Motorised Blinds', text: 'Motorised Blinds' },
  { value: 'Remote Blinds', text: 'Remote Blinds' },
  { value: 'Solid Wood/Hard Wood', text: 'Solid Wood/Hard Wood' },
  { value: 'Aluminium Profile', text: 'Aluminium Profile' },
  { value: 'Installation Charges', text: 'Installation Charges' },
  { value: 'Engineering Wood', text: 'Engineering Wood' },
  { value: 'Foam Underlays', text: 'Foam Underlays' },
  { value: 'RO Water Purifier', text: 'RO Water Purifier' },
  { value: 'Vertical Blinds', text: 'Vertical Blinds' },
  { value: 'Venetion Blinds', text: 'Venetion Blinds' },
  { value: 'Zebra Blinds', text: 'Zebra Blinds' },
  { value: 'Wooden Blinds', text: 'Wooden Blinds' },
  { value: 'Roman Blinds', text: 'Roman Blinds' },
  { value: 'Drapens Rods', text: 'Drapens Rods' },
  { value: 'Curtain Track', text: 'Curtain Track' },
  { value: 'Rubber Tile', text: 'Rubber Tile' },
  { value: 'Rubber Roll', text: 'Rubber Roll' },
  { value: 'Floor Covering Mats', text: 'Floor Covering Mats' },
  { value: 'Curtain Fabric', text: 'Curtain Fabric' },
  { value: 'Aluminium Louver', text: 'Aluminium Louver' },
  { value: 'Charcoal Panel', text: 'Charcoal Panel' },
  { value: 'Wooden Furniture', text: 'Wooden Furniture' },
  { value: 'Chair', text: 'Chair' },
  { value: 'Table', text: 'Table' },
  { value: 'Furniture', text: 'Furniture' },
  { value: 'TV', text: 'TV' },
  { value: 'Modular Kitchen', text: 'Modular Kitchen' },
  { value: 'R.O. Water Purifier', text: 'R.O. Water Purifier' },
  { value: 'Moulding', text: 'Moulding' },
  { value: 'Electrical Ware', text: 'Electrical Ware' },
  { value: 'Electrical Switch', text: 'Electrical Switch' },
  { value: 'Fire Extinguisher', text: 'Fire Extinguisher' },
  { value: 'Monitor', text: 'Monitor' },
  { value: 'Projector', text: 'Projector' },
  { value: 'Painting', text: 'Painting' },
  { value: 'Computer and Monitor', text: 'Computer and Monitor' }
];
export const HSN_LIST =[
  { value: '', text: 'Select HSN' },
  { value: '3919', text: '3919' },
  { value: '39259090', text: '39259090' },
  { value: '48239019', text: '48239019' },
  { value: '39181090', text: '39181090' },
  { value: '73269099', text: '73269099' },
  { value: '44129990', text: '44129990' },
  { value: '48142000', text: '48142000' },
  { value: '57032990', text: '57032990' },
  { value: '996793', text: '996793' },
  { value: '76042100', text: '76042100' },
  { value: '998540', text: '998540' },
  { value: '3925', text: '3925' },
  { value: '630399', text: '630399' },
  { value: '83024190', text: '83024190' },
  { value: '49119990', text: '49119990' },
  { value: '39269099', text: '39269099' },
  { value: '7604', text: '7604' },
  { value: '40169100', text: '40169100' },
  { value: '57033090', text: '57033090' },
  { value: '4409', text: '4409' },
  { value: '6306', text: '6306' },
  { value: '850140', text: '850140' },
  { value: '830241', text: '830241' },
  { value: '4418', text: '4418' },
  { value: '4016', text: '4016' },
  { value: '998512', text: '998512' },
  { value: '84212190' ,text: '84212190'},
  {value: '63039200' ,text: '63039200'},
  {value: '39253000' ,text: '39253000'},
  {value: '44213000' ,text: '44213000'},
  {value: '83024900' ,text: '83024900'},
  {value: '40094100' ,text: '40094100'},
  {value: '40169100' ,text: '40169100'},
  { value: '630311' ,text: '630311'},
  { value: '940340', text: '940340' },
  { value: '940350', text: '940350' },
  { value: '8528', text: '8528' },
  { value: '8480', text: '8480' },
  { value: '8538', text: '8538' },
  { value: '85352111', text: '85352111' },
  { value: '3813', text: '3813' },
  { value: '320910', text: '320910' },
  { value: '85284900', text: '85284900' }
];

export const GST_LIST =[
  { value: '', text: 'Select GST' },
  { value: '5', text: '5%' },
  { value: '12', text: '12%' },
  { value: '18', text: '18%' },
  { value: '21', text: '21%' },
  { value: '28', text: '28%' }
];

export const PER_QTY_RATE_LIST =[
  { value: '', text: 'Select Rate Per Quantity' },
  { value: 'pcs', text: 'Piece' },
  { value: 'sqft', text: 'Sqft' },
  { value: 'roll', text: 'roll' },
  { value: 'rft', text: 'rft' },
  { value: 'sqmtr', text: 'sqmtr' }
];

export const TAX_TYPE_LIST =[
  { value: '', text: 'Select tax type' },
  { value: 'state', text: 'Within State' },
  { value: 'central', text: 'Outside State' }
];


export const PRODUCT_HSN_MAPPER = {"Sun Film": "3919" ,
"WPC Tube/WPC Pond": "39259090" ,
"Metal Keel": "83024190" ,
"LAM Sheets 6mm": "48239019" ,
"WPC Exterior": "39181090" ,
"WPC Dekin": "39181090" ,
"WPC L Profile": "39259090" ,
"Deck Clip SS": "73269099" ,
"WPC Panel": "39259090" ,
"Clip Wall Panel": "83024190" ,
"Laminate Flooring": "44129990" ,
"Beading": "44129990" ,
"Skirting": "44129990" ,
"Wallpaper": "48142000" ,
"Profile": "44129990" ,
"Carpet Tile": "57032990" ,
"Wall to Wall Carpets": "57032990" ,
"Aluminium Extruded": "76042100" ,
"Aluminium Louver":"76042100",
"WPC Baffle Ceiling": "39259090" ,
"Suspension Bar": "39259090" ,
"Screw": "3925" ,
"Roller Blinds": "63039200" ,
"Metal Rod": "83024190" ,
"Blinds": "630399" ,
"Freight & Cartage Charges": "996793" ,
"Packing Charges": "998540" ,
"Carpets": "57033090" ,
"Catalogue": "49119990" ,
"Plastic Moulding": "39269099" ,
"Aluminium Section": "7604" ,
"Rubber Floor": "40169100" ,
"SPC Flooring": "39181090" ,
"SPC Skirting/Profile/Beading": "39181090" ,
"PVC Flooring/Tiles": "39181090" ,
"Artificial Grass": "57033090" ,
"PVC Panel": "39259090" ,
"IPE Wood": "4409" ,
"Kumaru Wood": "4409" ,
"Awning/Canopy": "6306" ,
"Motorised Blinds": "850140" ,
"Remote Blinds": "830241" ,
"Solid Wood/Hard Wood": "4409" ,
"Aluminium Profile": "7604" ,
"Installation Charges": "998512" ,
"Engineering Wood": "4418" ,
"Foam Underlays": "4016" ,
"RO Water Purifier": "84212190",
"Vertical Blinds": "63039200",
"Venetion Blinds": "39253000",
"Zebra Blinds": "39253000",
"Wooden Blinds": "44213000",
"Roman Blinds": "63039200",
"Drapens Rods": "83024900",
"Curtain Track": "83024900",
"Rubber Tile": "40094100",
"Rubber Roll": "40094100",  
"Floor Covering Mats": "40169100",
"Curtain Fabric":"630311" ,
"Charcoal Panel":"39269099",
"Wooden Furniture" :"940340",
"Chair":"940340",
"Table": "940340",
"Furniture":"940350",
"TV":"8528",
"Modular Kitchen":"940340",
"Moulding":"8480",
"Electrical Ware":"8538",
"Electrical Switch":"85352111",
"Fire Extinguisher":"3813",
"Monitor":"8528",
"Projector":"8528",
"Painting":"320910",
"Computer and Monitor":"85284900"
}

export const PRODUCT_UPLOADS = [
    {value: 'select', text: 'Select Product'},
    {value: 'agrass', text: 'agrass'},
    {value: 'wallpapers', text: 'wallpapers'},
    {value: 'solar', text: 'solar'},
    {value: 'wall2wallc', text: 'wall2wallc'},
    {value: 'awnings', text: 'awnings'},
    {value: 'blinds', text: 'blinds'},
    {value: 'ceilings', text: 'ceilings'},
    {value: 'claddings', text: 'claddings'},
    {value: 'doormats', text: 'doormats'},
    {value: 'carpettiles', text: 'carpettiles'},
    {value: 'nexa', text: 'nexa'},
    {value: 'pvc', text: 'pvc'},
    {value: 'woodenfloorings', text: 'woodenfloorings'},
    {value: 'fundermax', text: 'fundermax'},
    {value: 'aluminiumlouverpanels', text: 'aluminiumlouverpanels'},
    {value: 'indoorwpclouvers', text: 'indoorwpclouvers'},
    {value: 'outdoorwpclouvers', text: 'outdoorwpclouvers'},
    {value: 'hpl', text: 'hpl'},
    {value: '3d_panels', text: '3d_panels'},
    {value: 'fabio', text: 'fabio'},
    {value: 'floorings', text: 'floorings'},
    {value: 'newarrivals', text: 'newarrivals'},
    {value: 'wpcbaffleceilings', text: 'wpcbaffleceilings'},
    {value: 'soffitpanels', text: 'soffitpanels'},
    {value: 'gym', text: 'gym'},
    {value: 'verticalgarden', text: 'verticalgarden'},
    {value: 'foampanels', text: 'foampanels'}
  ]
  
export const ProductDropdowns = [
  { id: 1, name: 'Claddings', link: '/claddings' },
  { id: 2, name: 'Awnings', link: '/awnings' },
  { id: 3, name: 'Blinds', link: '/blinds' },
  { id: 4, name: 'Wall-2-Wall Carpets', link: '/wall2wall' },
  { id: 5, name: 'Ceilings', link: '/ceilings' },
  { id: 6, name: 'Solar Films', link: '/solar' },
  { id: 8, name: 'Artificial Grass', link: '/agrass' },
  { id: 9, name: 'Door Mats', link: '/doormats' },
  { id: 10, name: 'PVC Panels', link: '/pvcpanels' },
  { id: 11, name: 'Modular Carpet Tiles', link: '/modulercarpets' },
  { id: 12, name: 'Nexa Carpets', link: '/nexacarpets' },
  { id: 13, name: 'Fundermax', link: '/fundermax' },
  { id: 14, name: 'Aluminium Louver Panels', link: '/aluminiumlouverpanels' },
  { id: 15, name: 'Indoor WPC Panels', link: '/indoorwpclouvers' },
  { id: 16, name: 'Outdoor WPC Panels', link: '/outdoorwpclouvers' },
  { id: 17, name: 'HPL', link: '/hpl' },
  { id: 18, name: 'Wallpapers', link: '/wallpapers' },
  { id: 19, name: 'Wooden Floorings', link: '/woodenfloorings' },
  { id: 20, name: 'WPC Baffle Celings', link: '/wpcbaffleceilings' },
  { id: 21, name: 'Soffit Panels', link: '/soffitpanels' },
  { id: 22, name: 'Gym Floorings', link: '/gymfloorings' },
  { id: 23, name: 'Vertical Gardens', link: '/verticalgarden' },
  { id: 24, name: 'Foam Panels', link: '/foampanels' },
  { id: 24, name: 'Acoustic Baffle Ceilings', link: '/acousticbaffleceilings' }
];


export const carouselItems = [
  {
    id: 1,
    image: BASE_URL + 'download/wallpapers/wall41',
    caption: '3D Wallpapers',
  },
  {
    id: 2,
    image: BASE_URL + 'download/woodenfloorings/wood1',
    caption: 'Wooden Floorings',
  },
  {
    id: 3,
    image: BASE_URL + 'download/claddings/cladding1',
    caption: 'Exterior Claddings',
  },
  {
    id: 4,
    image: BASE_URL + 'download/acousticbaffleceilings/acousticbaffleceilings1',
    caption: 'Acoustic Baffle Ceilings',
  },
  {
    id: 5,
    image: BASE_URL + 'download/ceilings/ceiling1',
    caption: 'Ceilings',
  },
  {
    id: 6,
    image: BASE_URL + 'download/awnings/awning1',
    caption: 'Awnings',
  },
  {
    id: 7,
    image: BASE_URL + 'download/wall2wallc/wall2wall1',
    caption: 'Wall-2-Wall Carpets',
  },
  {
    id: 8,
    image: BASE_URL + 'download/blinds/blinds1',
    caption: 'Blinds',
  },
  {
    id: 9,
    image: BASE_URL + 'download/solar/solar1',
    caption: 'Solar Films',
  },
  {
    id: 10,
    image: BASE_URL + 'download/agrass/agrass1',
    caption: 'Artificial Grass',
  },
  {
    id: 11,
    image: BASE_URL + 'download/doormats/doormats1',
    caption: 'Door Mats',
  },
  {
    id: 12,
    image: BASE_URL + 'download/pvc/pvc2',
    caption: 'Pvc Panels',
  },
  {
    id: 13,
    image: BASE_URL + 'download/carpettiles/carpettiles1',
    caption: 'Moduler Carpet Tiles',
  },
  {
    id: 14,
    image: BASE_URL + 'download/nexa/nexa1',
    caption: 'Nexa Carpets',
  }
];