import React, { Component } from "react";
import { Navbar, Nav, NavDropdown,FormControl} from "react-bootstrap";
import Enquiry from "./enquirypopup";
import { Button } from "react-bootstrap";
import {ProductDropdowns,BASE_URL} from "./utility";
class MenuBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTerm: '',
      filteredItems: []
    };
    this.items =ProductDropdowns;
  }

  handleSearch = (e) => {
    const searchTerm = e.target.value;
    this.setState({ searchTerm });

    const filteredItems = this.items.filter((item) =>
      item.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    this.setState({ filteredItems });
  };

  handleItemClick = (link) => {
    window.open(link, '_blank');
  };

  render() {
    const { searchTerm, filteredItems } = this.state;
    return (
      <div>
        <Navbar  variant="dark" expand="lg" className="rounded" >
          <Navbar.Brand href="/"><img height={40} width={40} src={BASE_URL+"download/general/logo"} alt="Logo" /> </Navbar.Brand>
          <NavDropdown title="Search Products" id="basic-nav-dropdown" >
          <FormControl
            type="text"
            placeholder="Search"
            value={searchTerm}
            onChange={this.handleSearch}
          />
         
          {filteredItems.length > 0 ? (
            filteredItems.map((item) => (
              <NavDropdown.Item
                key={item.id}
                href="#"
                onClick={() => this.handleItemClick(item.link)}
              >
                {item.name}
              </NavDropdown.Item>
            ))
          ) : (
            <NavDropdown.Item disabled>No matching items found</NavDropdown.Item>
          )}
        </NavDropdown>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
              <Nav.Link href="/newproducts"  className="btn btn-success" style={{color:"white"}}> New Arrivals</Nav.Link>
              <Nav.Link href="/about">About Us</Nav.Link>
              <NavDropdown title="Products" href="/products" id="basic-nav-dropdown">
                <NavDropdown.Header>
                  <Button variant="secondary" href="/products">
                    All Products
                </Button>
                </NavDropdown.Header>
                <NavDropdown.Item href="/wallpapers"> Wallpapers </NavDropdown.Item>
                <NavDropdown.Item href="/acousticbaffleceilings"> Acoustic Baffle Ceilings </NavDropdown.Item>
                <NavDropdown.Item href="/woodenfloorings">Wooden Floorings </NavDropdown.Item>
                <NavDropdown.Item href="/claddings">Claddings</NavDropdown.Item>
                <NavDropdown.Item href="/awnings">Awnings</NavDropdown.Item>
                <NavDropdown.Item href="/blinds">Blinds</NavDropdown.Item>
                <NavDropdown.Item href="/wall2wall"> Wall-2-Wall Carpets</NavDropdown.Item>
                <NavDropdown.Item href="/ceilings">Ceilings</NavDropdown.Item>
                <NavDropdown.Item href="/wpcbaffleceilings">WPC Baffle Ceilings</NavDropdown.Item>
                <NavDropdown.Item href="/soffitpanels">Soffit Panels</NavDropdown.Item>
                <NavDropdown.Item href="/solar">Solar Films</NavDropdown.Item>
                <NavDropdown.Item href="/agrass">Artificial Grass</NavDropdown.Item>
                <NavDropdown.Item href="/doormats">Door Mats</NavDropdown.Item>
                <NavDropdown.Item href="/pvcpanels">PVC Panels</NavDropdown.Item>
                <NavDropdown.Item href="/foampanels">Foam Panels</NavDropdown.Item>
                <NavDropdown.Item href="/verticalgarden">Vertical Gardens</NavDropdown.Item>
                <NavDropdown.Item href="/modulercarpets"> Modular Carpet Tiles </NavDropdown.Item>
                <NavDropdown.Item href="/nexacarpets">Nexa Carpets</NavDropdown.Item>
                <NavDropdown.Item href="/fundermax"> Fundermax </NavDropdown.Item>
                <NavDropdown.Item href="/aluminiumlouverpanels"> Aluminium Louver Panels </NavDropdown.Item>
                <NavDropdown.Item href="/indoorwpclouvers"> Indoor WPC Panels</NavDropdown.Item>
                <NavDropdown.Item href="/outdoorwpclouvers"> Outdoor WPC Panels</NavDropdown.Item>
                <NavDropdown.Item href="/hpl">HPL </NavDropdown.Item>
                <NavDropdown.Item href="/gymfloorings">Gym Floorings </NavDropdown.Item>
              </NavDropdown>
              <Nav.Link href="/brochures">Brochures</Nav.Link>
              <Enquiry />
              &nbsp;
            </Nav>
            <Nav className="ml-auto">
            <Button variant="success" hidden={!localStorage.getItem("token")}onClick={(e) => {
                e.preventDefault();
                window.location.href = '/operations';
              }}>
                Operations
              </Button>
              <Button variant="success" hidden={localStorage.getItem("token")}onClick={(e) => {
                e.preventDefault();
                window.location.href = '/auth';
              }}>
                Login
              </Button>
              &nbsp;
              <Button variant="light" hidden={!localStorage.getItem("token")}onClick={(e) => {
                e.preventDefault();
                localStorage.removeItem("token");
                window.location.href = '/';
              }}>
                Logout
              </Button>
              </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    );
  }
}

export default MenuBar;
