import React from "react";
import emailjs from "emailjs-com";
import { Form } from "react-bootstrap";
import swal from 'sweetalert';
export default function MailClient() {
  function sendEmail(e) {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_r97pgaj",
        "template_l21vi4w",
        e.target,
        "user_58IRKUJ9wfCmk1ka8yigY"
      )
      .then(
        (result) => {
          console.log(result.text)
          if (result.text === 'OK') {
            swal({
              title: 'Thank you',
              text: 'Request Submitted Successfully !!',
              type: 'warning',
              confirmButtonColor: "#DD6B55"
            });
          }
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  }

  return (
    <div >
      <style>{"body { background-color: #0F092B;}"}</style>
      <Form onSubmit={sendEmail}>
        <Form.Group controlId="exampleForm.ControlInput1">
          <Form.Label style={{ color: "white" }}>Email address</Form.Label>
          <Form.Control
            type="email"
            name="user_email"
            placeholder="name@example.com"
          />
        </Form.Group>
        <Form.Group controlId="exampleForm.ControlInput2">
          <Form.Label style={{ color: "white" }}>Name</Form.Label>
          <Form.Control type="text" placeholder="name" name="user_name" />
        </Form.Group>
        <Form.Group controlId="exampleForm.ControlInput3">
          <Form.Label style={{ color: "white" }}>Contact Number</Form.Label>
          <Form.Control
            type="text"
            placeholder="contact number"
            name="contact_number"
          />
        </Form.Group>
        <Form.Group controlId="exampleForm.ControlTextarea1">
          <Form.Label style={{ color: "white" }}>Description</Form.Label>
          <Form.Control
            name="message"
            placeholder="Description"
            as="textarea"
            rows={3}
          />
        </Form.Group>
        <Form.Group controlId="exampleForm.ControlInput3">
          <Form.Control
            style={{ background: "green", color: "black" }}
            type="submit"
            name="Send"
          />
        </Form.Group>
      </Form>
      <div>&nbsp;</div>
    </div>
  );
}
