import React, { Component } from "react";
import SocialMediaLinks from "./socialmedialinks";
import MenuBar from "./navbar";
import { Card } from "react-bootstrap";
import Header from "./header";
import {BASE_URL} from "./utility";
import Contact from "./contact";
class About extends Component {
  state = {};
  render() {
    return (
      <div style={{ backgroundImage:`url(${BASE_URL+"download/general/bg"})` }}>
        <Header />
        <MenuBar />
        <br></br>
        <Card style={{ color: "black" }}>
          <Card.Body>
            <Card.Title>History</Card.Title>
            <hr></hr>
            
              Established in the year of 2005, Rainbow Touch is the leading
              manufacturer, wholesaler and trader of Designer Wallpapers, Wooden
              Flooring, Artificial Grass, False Ceilings, Door Mats and much
              more..
            
          </Card.Body>
        </Card>
        <br></br>
        <Card style={{ color: "black" }}>
          <Card.Body>
            <Card.Title>Why Us?</Card.Title>
            <hr></hr>
            
              We procure these products from some of the trustworthy and
              certified vendors of the market, who utilize quality tested raw
              material and advanced machines. At their well-developed premises,
              the offered products are developed by our vendors in conformity
              with the international standards. We provide products of quality
              as per the market standards.
            

            We import some of the products as well i.e. PVC Panels etc.
          </Card.Body>
        </Card>
        <br></br>
        <Card style={{ color: "black" }}>
          <Card.Body>
            <Card.Title>Overview</Card.Title>
            <hr></hr>
            
              Rainbow Touch is one of the top player in 
              Wall-Paper Dealers category in NCR. This well-known
              establishment acts as a one-stop destination servicing customers
              both local and from other parts of Delhi. Over the course of its
              journey, this business has established a firm foothold in it’s
              industry. The belief that customer satisfaction is as important as
              their products and services, have helped this establishment garner
              a vast base of customers, which continues to grow by the day. This
              business employs individuals that are dedicated towards their
              respective roles and put in a lot of effort to achieve the common
              vision and larger goals of the company.
            
            
              In the near future, this business aims to expand its line of
              products and services and cater to a larger client base. In Delhi,
              this establishment occupies a prominent location in Lado
              Sarai-Mehrauli. It is an effortless task in commuting to this
              establishment as there are various modes of transport readily
              available. It is at Golf Course Rd, Near Mehrauli, which makes it
              easy for first-time visitors in locating this establishment. It is
              known to provide top service in the following categories: Vinyl
              Flooring Dealers, Customised Wall Paper Dealers, Vinyl
              Flooring-Premier Dealer.
            
              Rainbow Touch has a wide range of products
              and services to cater to the varied requirements of their
              customers. The staff at this establishment are courteous and
              prompt at providing any assistance. They readily answer any
              queries or questions that you may have.
          </Card.Body>
        </Card>
        <br></br>
        <Contact/>
        <div>&nbsp;</div>
        <div>&nbsp;</div>
        <SocialMediaLinks />
        <div>&nbsp;</div>
      </div>
    );
  }
}

export default About;
