import React,{Component} from 'react';
import ReactTable from "react-table-6";
import "react-table-6/react-table.css";
import MenuBar from "./navbar";
import SocialMediaLinks from "./socialmedialinks";
import Header from  './header';
import {BASE_URL} from "./utility";
import { Redirect } from 'react-router-dom';


class InvoiceReport extends Component {

  
    constructor() {
        super()
        this.state = {
          type:'',
          items: []
        };
      }

      handleDropdownChange = (event) => {
        const selectedValue = event.target.value;
        let result=[];
        fetch(BASE_URL+'/getInvoiceDetails/'+selectedValue, {
       method: 'get',
       headers: {'Content-Type':'application/json'}
       }).then((response) => response.json())
       .then((responseJson) => { 
        for(let i=0;i<responseJson.length;i++)
        {
         let obj= {};
         obj["refnum"] = responseJson[i]["refnum"];
         obj["gstType"] = responseJson[i]["gstType"]; 
         let data=JSON.parse(responseJson[i]["data"])
         obj["date"] = data["orderDate"].split(" ")[0];  
         obj["data"]= responseJson[i]["data"];
         result.push(obj)
        }

         this.setState({
            items: result,
            type:selectedValue
          });
       })
        .catch((error) => {
          console.error(error);
        });
        return result;
     }
    render(){ 
        const handleDownload = (e) => {
            const value = e.target.value;
            fetch(BASE_URL + 'downloadInvoice', {
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                body: value
              })
                .then(res => res.blob())
                .then(blob => {
                  var file = window.URL.createObjectURL(blob);
                  window.location.assign(file);
                });
          }  
          const handleDelete = (e) => {
            const value = e.target.value;
            e.preventDefault();
            if (window.confirm('Are you sure you want delete invoice:'+value)) {
            fetch(BASE_URL + 'deleteInvoice/'+this.state.type+'/'+value, {
                method: 'delete'
              })
                .then(res => res.text())
                .then(text => {
                  window.location.href = '/operations';  
                });
              }
          }      
        const data =this.state.items
        const columns= [{
            Header:"Invoice No.",
            accessor:'refnum',
            width: 100
        }, {
            Header:"Tax Type",
            accessor:'gstType',
            width: 75
        },{
            Header:"Date",
            accessor:'date',
            width: 120
        },{
            Header:"Download",
            accessor:'data',
            width: 100,
            Cell: cell => (
                <button value={cell.row.data} onClick={handleDownload}>
                  Download
                </button>
              )
        },{
          Header:"Delete",
          accessor:'refnum',
          width: 100,
          Cell: cell => (
              <button value={cell.row.refnum} onClick={handleDelete}>
                Delete
              </button>
            )
      }]
      if (!localStorage.getItem("token"))
      return <Redirect to='/auth' />
      else
        return (
            <div style={{ backgroundImage:`url(${BASE_URL+"download/general/bg"})` }}>
          <Header />
        <center>
          <MenuBar />
          <h2 style={{ color: "white" }}>Invoice Reports </h2>
        <div>
       
        <select style={{padding:"5px"}} onChange={this.handleDropdownChange}>
          <option value="">Select Report Type</option>
          <option value="tax">Tax Invoice</option>
          <option value="pro">Pro forma Invoice</option>
          <option value="credit">Credit Note</option>
        </select>
        {data.length>0 && (
             <div className="table-container"> 
           <ReactTable   style={{ backgroundColor: "white" ,border: "2px solid" ,borderRadius: "20px",width: "500px"}}
               data={data}  
               columns={columns}  
               defaultPageSize = {10}  
           />  
       </div> 
        )}
      </div>
            
          </center>
          <div>&nbsp;</div>
        <div>&nbsp;</div>
        <SocialMediaLinks />
        <div>&nbsp;</div>
      </div>
        )
    }

}

export default InvoiceReport;  