import React from "react";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.css";
import About from "./components/about";
import Home from "./components/home";
import Contact from "./components/contact";
import Products from "./components/products";
import NewProducts from "./components/newarrivals";
import Brochures from "./components/brochures";
import Auth from "./components/auth";
import Operations from "./components/operations";
import FileUploadComponent from "./components/imageuploader"
import InvoiceForm from "./components/invoiceform"
import InvoiceReports from "./components/invoicereports"
import ImageDeletion from './components/imageDeletion';
import Product from "./components/product";
import { BrowserRouter, Route, Switch } from "react-router-dom";
ReactDOM.render(
  <div>
    <BrowserRouter>
      <Switch>
        <Route path="/" component={Home} exact={true} />
        <Route path="/about" component={About} />
        <Route path="/contact" component={Contact} />
        <Route path="/auth" component={Auth} />
        <Route path="/brochures" component={Brochures} />
        <Route path="/products" component={Products} />
        <Route path="/newproducts" component={NewProducts} />
        <Route path="/operations" component={Operations} />
        <Route path="/uploadimage" component={FileUploadComponent} />
        <Route path="/invoicereports" component={InvoiceReports} />
        <Route path="/imageDeletion" component={ImageDeletion}/>

        <Route path="/taxinvoice" render={() => <InvoiceForm type="tax" desc="Tax Invoice"/>} />
        <Route path="/performainvoice" render={() => <InvoiceForm type="pro" desc="Pro forma invoice / Quotation"/>} />
        <Route path="/creditnote" render={() => <InvoiceForm type="credit" desc="Credit Note"/>} />
        <Route path="/wallpapers" render={() => <Product product="wallpapers" desc="Wallpapers"/>} />
        <Route path="/agrass"  render={() => <Product product="agrass" desc="Artificial Grass"/>} />
        <Route path="/blinds" render={() => <Product product="blinds" desc="Blinds"/>} />
        <Route path="/ceilings" render={() => <Product product="ceilings" desc="Ceilings"/>} />
        <Route path="/wpcbaffleceilings" render={() => <Product product="wpcbaffleceilings" desc="Wpc Baffle Ceilings"/>} />
        <Route path="/soffitpanels" render={() => <Product product="soffitpanels" desc="Soffit Panels"/>} />
        <Route path="/claddings" render={() => <Product product="claddings" desc="Claddings"/>} />
        <Route path="/doormats" render={() => <Product product="doormats" desc="Door Mats"/>} />
        <Route path="/modulercarpets" render={() => <Product product="carpettiles" desc="Modular Carpet Tiles"/>} />
        <Route path="/awnings" render={() => <Product product="awnings" desc="Awnings"/>} />
        <Route path="/pvcpanels" render={() => <Product product="pvc" desc="PVC Panels"/>} />
        <Route path="/foampanels"  render={() => <Product product="foampanels" desc="Foam Panels"/>} />
        <Route path="/verticalgarden" render={() => <Product product="verticalgarden" desc="Vertical Gardens Exterior & Interior"/>} />
        <Route path="/nexacarpets" render={() => <Product product="nexa" desc="Nexa"/>} />
        <Route path="/wall2wall" render={() => <Product product="wall2wallc" desc="Wall 2 Wall Carpets"/>} />
        <Route path="/woodenfloorings" render={() => <Product product="woodenfloorings" desc="Wooden Floorings"/>} />
        <Route path="/gymfloorings" render={() => <Product product="gym" desc="Gym Floorings"/>} />
        <Route path="/solar" render={() => <Product product="solar" desc="Solar Films"/>} />
        <Route path="/fundermax" render={() => <Product product="fundermax" desc="Fundermax"/>} />
        <Route path="/aluminiumlouverpanels" render={() => <Product product="aluminiumlouverpanels" desc="Alumininum Louver Panels"/>} />
        <Route path="/indoorwpclouvers" render={() => <Product product="indoorwpclouvers" desc="Indoor WPC Louvers"/>} />
        <Route path="/outdoorwpclouvers" render={() => <Product product="outdoorwpclouvers" desc="Outdoor WPC Louvers"/>} />
        <Route path="/hpl" render={() => <Product product="hpl" desc="HPL"/>} />
        <Route path="/acousticbaffleceilings" render={() => <Product product="acousticbaffleceilings" desc="Acoustic Baffle Ceilings"/>} />
      </Switch>
    </BrowserRouter>
  </div>,
  document.getElementById("root")
);
