import React from "react";
import swal from "sweetalert";
import {BASE_URL} from "./utility";
import MenuBar from "./navbar";
import SocialMediaLinks from "./socialmedialinks";
import Header from './header';
class NameForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {username: '',password:''};
    this.handleUserChange = this.handleUserChange.bind(this);
    this.handlePassChange = this.handlePassChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  
  handleUserChange(event) {
    this.setState({username: event.target.value});
  }

  handlePassChange(event) {
    this.setState({password: event.target.value});
  }
  handleSubmit(event) {
    event.preventDefault();
     fetch(BASE_URL+'login', {
   method: 'post',
   headers: {'Content-Type':'application/json'},
   body:  JSON.stringify({
    "username": this.state.username,
    "password": this.state.password
   })}).then((response) => response.json())
   .then((responseJson) => {
     if (responseJson.result === 'success')
     {
      localStorage.setItem("token",responseJson.token)
      window.location.href = '/operations';
     }else
     {
      swal(responseJson.message);
     }

   })
    .catch((error) => {
      console.error(error);
    });
 };

  render() {
    return (
      <div style={{ backgroundImage:`url(${BASE_URL+"download/general/bg"})` }}>
             <Header />
    
        <center>
          <h2 style={{ color: "white"}}>Delete Images </h2>
          <MenuBar />
          </center>
<div className="Auth-form-container" style={{height: '55vh',display: 'flex', justifyContent:'center', alignItems:'center'}}>
      <form className="Auth-form" onSubmit={this.handleSubmit} style={{background:"white",borderRadius:"10px",width:"40%" }}>
        <div className="Auth-form-content" >
          <h3 className="Auth-form-title" style={{display: 'flex',  justifyContent:'center', alignItems:'center',color:"black"}}>Sign In </h3>
          <center>
            <div className="form-group mt-3">
            <input
              type="text"
              className="form-control mt-1"
              placeholder="Enter username" 
              style={{width:"80%"}}
              value={this.state.username} onChange={this.handleUserChange}
            />
          </div>
          <div className="form-group mt-3">
            <input
              type="password"
              className="form-control mt-1"
              placeholder="Enter password"
              style={{width:"80%"}}
              value={this.state.password} onChange={this.handlePassChange}
            />
          </div>
          </center>

          <div className="d-grid gap-2 mt-3" style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
            <button type="submit" className="btn btn-primary">
              Login
            </button>
          </div>
        </div>
      </form>
    </div>
    <div>&nbsp;</div>
        <SocialMediaLinks />
        <div>&nbsp;</div>
    </div>
  
    
    );
  }
}

export default NameForm;